let USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

const validPhone = (phone) => {
    const pattern = /^[+]{1}(?:[0-9\-\\(\\)\\/.]\s?){6,15}[0-9]{1}$/;
    //const pattern = /^\+[0-9]{1,3}\.[0-9]{4,14}(?:x.+)?$/;

    return pattern.test(phone);
}

export {
    USDollar,
    validPhone
}