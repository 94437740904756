import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import logo1 from '../assets/1.png'
import {Link, Box, FormControl, InputLabel, Select, MenuItem, Avatar} from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person'
import {useAuth} from "../hooks/useAuth";
import {useTranslation} from "react-i18next";

export default function Header() {
    const {logout} = useAuth();
    const {i18n, t} = useTranslation();

    const handleLogout = () => {
        if (window.confirm("Are you sure you want to sign out?")) {
            logout({replace: true}).catch(e => {
                console.log(e);
            });
        }
    }

    const onChange = (e) => {
        const lang_code = e.target.value;
        i18n.changeLanguage(lang_code);
    }

    return (
        <div style={{
            backgroundColor: "rgba(3, 42, 81, 1)",
            width: '100%'
        }}>
            <Box display='flex' flexDirection='row' alignItems='center' flexGrow={1} sx={{height: '120px'}}>
                <Box flexGrow={1} p={1}>
                    <Link component={RouterLink} to="/">
                        <img style={{width: '100%',maxWidth: '320px'}} alt='logo' src={logo1}/>
                    </Link>
                </Box>

                <Box p={1}>
                    <FormControl variant="standard" sx={{ minWidth: 80 }}>
                        <InputLabel id="demo-simple-select-standard-label" sx={{color: 'white'}}>
                            {t("general_language")}
                        </InputLabel>
                        <Select
                            variant="standard"
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={i18n.language}
                            onChange={onChange}
                            label="Language"
                            sx={{color: 'white'}}
                        >
                            <MenuItem value='es'>
                                {t("general_spanish")}
                            </MenuItem>
                            <MenuItem value='en'>
                                <em>{t("general_english")}</em>
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Box>

                <Box p={1}>
                    <Link href='/profile' style={{textDecoration:'none', color:'white' }}>
                        <Avatar>
                            <PersonIcon/>
                        </Avatar>
                    </Link>
                </Box>

                <Box p={1}>
                    <Link href="#" onClick={ handleLogout } style={{textDecoration:'none', color:'white', }}>
                        <Avatar>
                            <LogoutIcon/>
                        </Avatar>
                    </Link>
                </Box>
            </Box>
        </div>
    )
}
