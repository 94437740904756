import React from 'react';
//import Header from "../components/Header";
import Footer from "../components/Footer";
import {Typography, Box, Link} from "@mui/material";
import ResponsiveAppBar from "../components/ResponsiveAppBar";

const TerminosInversion = () => {
    return <div>
        {/*<Header/>*/}
        <ResponsiveAppBar/>

        <Box display="flex" justifyContent="center">
            <Box flexGrow={1} style={{maxWidth: 1000}}>
                <Box p={1} m={1}>
                    <Typography gutterBottom fontWeight='bold'>
                        Prestige Capital Bank LLC Variable Income Investment Terms and Conditions
                    </Typography>

                    <ul>
                        <li>
                            <Link href="#en">
                                English
                            </Link>
                        </li>
                        <li>
                            <Link href="#es">
                                Spanish (Español)
                            </Link>
                        </li>
                    </ul>

                    <Typography gutterBottom fontWeight='bold' id="en">
                        Investment Overview
                    </Typography>

                    <Typography gutterBottom>
                        Prestige Capital Bank LLC ("PCB", "we", "us", "our") offers a variable income investment option utilizing a proprietary AI algorithm. This investment primarily allocates capital among various asset classes, including equities and cryptocurrencies ("Assets"). The AI algorithm aims to optimize returns, having achieved an approximate 22% Return on Investment (ROI) every six months over the past three years under conditions described herein.
                    </Typography>

                    <Typography fontWeight='bold' gutterBottom>
                        Eligibility and Investment Minimums
                    </Typography>

                    <ol>
                        <li>Investor Eligibility: This investment opportunity is available subject to our approval, which may be granted or withheld at our sole discretion.</li>
                        <li>Minimum Investment: The initial minimum investment required from each client is USD 100,000, which must be increased to at least USD 200,000 within six months of the initial investment.</li>
                    </ol>

                    <Typography fontWeight='bold' gutterBottom>
                        Investment Term and Liquidity
                    </Typography>

                    <ol>
                        <li>Investment Term: The funds invested will be illiquid for a minimum term of six months. This term may be extended based on asset availability and market conditions.</li>
                        <li>Liquidity: Withdrawals or liquidation of assets before the end of the specified term are generally not permitted, except under extraordinary circumstances which will be evaluated on a case-by-case basis.</li>
                    </ol>

                    <Typography fontWeight='bold' gutterBottom>
                        Risks and Risk Management
                    </Typography>

                    <ol>
                        <li>Market Risk: Investments are subject to market conditions and can fluctuate in value, potentially resulting in losses. The historical CAGR (Compound Annual Growth Rate) of 48% is based on past performance under a capital management of less than USD 100 million and is not a reliable indicator of future performance.</li>
                        <li>Cryptocurrency Volatility: Given the volatile nature of cryptocurrencies, investments can be highly variable and are subject to both rapid gains and declines.</li>
                        <li>AI Algorithm Limitations: The AI-driven management strategy is designed to adapt to market conditions; however, its effectiveness is reliant on algorithmic predictions, which are inherently subject to errors and limitations.</li>
                        <li>Derivative Instruments: While options and other credit derivatives are employed to hedge downside risks, they themselves carry risks of potentially significant losses and may not fully mitigate losses in more extreme market conditions.</li>
                        <li>Liquidity Risk: Due to the illiquid nature of the investment during the lock-up period, clients may not be able to access their funds according to their personal or business cash flow needs.</li>
                    </ol>

                    <Typography fontWeight='bold' gutterBottom>
                        No Guarantee of Performance
                    </Typography>

                    <Typography gutterBottom>
                        PCB does not guarantee any specific return on investment. The investment's performance is subject to complex market dynamics, and past performance is not indicative of future results.
                    </Typography>


                    <Typography fontWeight='bold' gutterBottom>
                        Regulatory and Legal Compliance
                    </Typography>

                    <ol>
                        <li>Compliance with Local Laws: All investment activities under this agreement will adhere to the laws and regulations of Ginebra. It is the responsibility of the client to ensure that their participation complies with local laws applicable in their jurisdiction.</li>
                        <li> Disclosure Requirements: We commit to transparently reporting all pertinent investment activities and financial positions as required by Ginebra regulations.</li>
                    </ol>

                    <Typography gutterBottom fontWeight='bold'>
                        Amendments and Termination
                    </Typography>

                    <Typography gutterBottom>
                        PCB reserves the right to make amendments to these terms and conditions as may be required by changes in law or in our operational procedures. Changes will be communicated to all affected clients in accordance with regulatory requirements. Clients may terminate their investment with PCB according to the terms specified herein, noting any financial implications or losses that may occur as a result of such termination prior to the end of the investment term.
                    </Typography>

                    <Typography gutterBottom fontWeight='bold'>
                        Acceptance of Terms
                    </Typography>

                    <Typography gutterBottom>
                        By investing in the variable income investment option, clients acknowledge their understanding and acceptance of these terms and conditions, including the associated risks and the speculative nature of the invested assets.
                    </Typography>

                    <Typography gutterBottom id="es">
                        en español:
                    </Typography>

                    <Typography gutterBottom fontWeight='bold'>
                        Términos y Condiciones de los Servicios Bancarios de Prestige Capital Bank LLC
                    </Typography>

                    <Typography fontWeight='bold' gutterBottom>
                        Descripción de los Servicios
                    </Typography>

                    <Typography gutterBottom>
                        Prestige Capital Bank LLC ("PCB", "nosotros") ofrece servicios bancarios conforme a las leyes aplicables en Ginebra. Estos servicios incluyen, pero no se limitan a, operaciones de cuenta corriente, servicios de ahorro, y otros servicios financieros permitidos por la ley.
                    </Typography>

                    <Typography fontWeight='bold' gutterBottom>
                        Acceso a los Servicios
                    </Typography>

                    <ol>
                        <li>Elegibilidad: Los servicios están disponibles para clientes que cumplan con nuestros criterios de elegibilidad, los cuales incluyen verificaciones de antecedentes financieros y de identidad conforme a las regulaciones vigentes.</li>
                        <li>Acceso a Cuentas: Los clientes pueden acceder a sus cuentas a través de métodos autorizados, incluidos servicios bancarios en línea, aplicaciones móviles y sucursales físicas.</li>
                    </ol>

                    <Typography fontWeight='bold' gutterBottom>
                        Uso de los Servicios
                    </Typography>

                    <ol>
                        <li>Responsabilidades del Cliente: Es responsabilidad del cliente mantener la seguridad de su información de acceso y realizar un seguimiento adecuado de todas las transacciones. El cliente deberá notificar inmediatamente a PCB sobre cualquier actividad sospechosa.</li>
                        <li>Modificaciones del Servicio: PCB se reserva el derecho de modificar, cambiar o discontinuar cualquier aspecto de los servicios bancarios en cualquier momento, sujeto a las leyes aplicables.</li>
                    </ol>

                    <Typography fontWeight='bold' gutterBottom>
                        Terminación y Suspensión de los Servicios
                    </Typography>

                    <Typography gutterBottom>
                        PCB puede terminar o suspender el acceso a los servicios bancarios a cualquier cliente que incumpla con los términos del acuerdo de servicios, las políticas de PCB o la ley aplicable.
                    </Typography>

                    <Typography fontWeight='bold' gutterBottom>
                        Términos y Condiciones de la Inversión de Ingresos Variables de Prestige Capital Bank LLC
                    </Typography>

                    <Typography fontWeight='bold' gutterBottom>
                        Descripción de la Inversión
                    </Typography>

                    <Typography gutterBottom>
                        Prestige Capital Bank LLC ofrece una opción de inversión de ingresos variables que utiliza un algoritmo de IA. Esta inversión asigna capital principalmente entre varias clases de activos, incluidas las acciones y las criptomonedas.
                    </Typography>


                    <Typography fontWeight='bold' gutterBottom>
                        Elegibilidad y Mínimos de Inversión
                    </Typography>

                    <ol>
                        <li>Elegibilidad del Inversor: Esta oportunidad de inversión está disponible sujeta a nuestra aprobación, que puede ser otorgada o retenida a nuestra discreción exclusiva.</li>
                        <li>Inversión Mínima: La inversión mínima inicial requerida de cada cliente es de USD 100,000, que debe aumentarse a al menos USD 200,000 dentro de los seis meses siguientes a la inversión inicial.</li>
                    </ol>

                    <Typography fontWeight='bold' gutterBottom>
                        Término de Inversión y Liquidez
                    </Typography>

                    <ol>
                        <li>Término de Inversión: Los fondos invertidos serán ilíquidos por un término mínimo de seis meses. Este término puede ser extendido basado en la disponibilidad de activos y las condiciones del mercado.</li>

                        <li>Liquidez: Generalmente no se permiten retiros o liquidación de activos antes del fin del término especificado, excepto bajo circunstancias extraordinarias que serán evaluadas caso por caso.</li>
                    </ol>

                    <Typography fontWeight='bold' gutterBottom>
                        Riesgos y Gestión de Riesgos
                    </Typography>


                    <ol>
                        <li>Riesgo de Mercado: Las inversiones están sujetas a las condiciones del mercado y pueden fluctuar en valor, lo que podría resultar en pérdidas. El CAGR histórico del 48% se basa en el rendimiento pasado bajo una gestión de capital de menos de USD 100 millones y no es un indicador confiable de resultados futuros.</li>

                        <li>Volatilidad de las Criptomonedas: Dada la naturaleza volátil de las criptomonedas, las inversiones pueden ser altamente variables y están sujetas tanto a rápidas ganancias como a declives.</li>

                        <li>Limitaciones del Algoritmo de IA: La estrategia de gestión impulsada por IA está diseñada para adaptarse a las condiciones del mercado; sin embargo, su efectividad depende de predicciones algorítmicas, que intrínsecamente están sujetas a errores y limitaciones.</li>

                        <li>Instrumentos Derivados: Aunque se utilizan opciones y otros derivados de crédito para cubrir los riesgos a la baja, estos mismos llevan riesgos de pérdidas potencialmente significativas y pueden no mitigar completamente las pérdidas en condiciones de mercado más extremas.</li>
                    </ol>

                    <Typography fontWeight='bold' gutterBottom>
                        Sin Garantía de Rendimiento
                    </Typography>

                    <Typography gutterBottom>
                        PCB no garantiza ningún retorno específico sobre la inversión. El rendimiento de la inversión está sujeto a dinámicas de mercado complejas, y el rendimiento pasado no es indicativo de resultados futuros.
                    </Typography>

                    <Typography fontWeight='bold' gutterBottom>
                        Cumplimiento Regulatorio y Legal
                    </Typography>

                    <ol>

                        <li>Cumplimiento con las Leyes Locales: Todas las actividades de inversión bajo este acuerdo se adherirán a las leyes y regulaciones de Ginebra. Es responsabilidad del cliente asegurarse de que su participación cumpla con las leyes locales aplicables en su jurisdicción.</li>

                        <li>Requisitos de Divulgación: Nos comprometemos a reportar transparentemente todas las actividades de inversión pertinentes y las posiciones financieras según lo requieran las regulaciones de Ginebra.</li>

                    </ol>

                    <Typography fontWeight='bold' gutterBottom>
                        Modificaciones y Terminación
                    </Typography>


                    <Typography gutterBottom>
                        PCB se reserva el derecho de hacer modificaciones a estos términos y condiciones según sea necesario por cambios en la ley o en nuestros procedimientos operativos. Los cambios serán comunicados a todos los clientes afectados de acuerdo con los requisitos regulatorios. Los clientes pueden terminar su inversión con PCB de acuerdo a los términos especificados aquí, notando cualquier implicación financiera o pérdidas que puedan ocurrir como resultado de tal terminación antes del fin del término de inversión.
                    </Typography>

                    <Typography fontWeight='bold' gutterBottom>
                        Aceptación de Términos
                    </Typography>

                    <Typography gutterBottom>
                        Al invertir en la opción de inversión de ingresos variables, los clientes reconocen su comprensión y aceptación de estos términos y condiciones, incluyendo los riesgos asociados y la naturaleza especulativa de los activos invertidos.
                    </Typography>
                </Box>
            </Box>
        </Box>
        <Footer/>
    </div>
}

export default TerminosInversion;