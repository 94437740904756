const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export async function loginUser (credentials) {
    return fetch(`${SERVER_URL}/auth/login/password`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(credentials)
    }).then(res => {
        return { isValid: res.ok, data: res.statusText }
    });
}

export async function checkStatus () {
    return fetch(`${SERVER_URL}/auth/status`, {
        method: 'POST',
        credentials: 'include'
    }).then(res => {
        return { isValid: res.ok, data: res.statusText }
    }).catch(e => {
        console.error(e);
        return { isValid: false };
    });
}

export async function logoutUser () {
    return fetch(`${SERVER_URL}/auth/logout`, {
        method: 'POST',
        credentials: 'include'
    }).then(res => {
        return { isValid: res.ok, data: res.statusText }
    });
}
