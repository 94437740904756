import React from 'react';
import Footer from "../../components/Footer";
import {Box, Button, TextField, Typography, Grid2} from "@mui/material";
import TransferDialog from "./TransferDialog";
import logo from "../../assets/LOGOS BLANCO PNG/CAPITAL BANK blanco-08.png";
import user_logo from "../../assets/IMAGENES AZUL PNG/PROFILE.png";
import {fetchData} from "../../hooks/fetchData";
import {Link} from "react-router-dom";
import ResponsiveAppBar from "../../components/ResponsiveAppBar";

const DomesticTransfer = () => {
    const [checked, setChecked] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [name, setName] = React.useState('');

    const showTerms = () => {
        setOpen(true);
    }

    React.useEffect(() => {
        fetchData().then(data => {
            setName(`${data.name} ${data.lastName}`)
        });
    });

    return <div>
        <ResponsiveAppBar/>
        <Box display="flex" justifyContent="center">
            <Box flexGrow={1} style={{maxWidth: 1000, paddingTop: 10, paddingBottom: 10, minHeight: 'calc(100vh - 180px)'}}>

                <Grid2 container spacing={2}>
                    <Grid2 size={{xs: 12, sm: 12, md:12, lg:6, xl:6}} align='center'>
                        <Box display='flex'
                             flexDirection='column'
                             alignItems='left' textAlign='left' p={1}>
                            <Typography variant='h3'>
                                {name}
                            </Typography>

                        </Box>
                        <hr style={{
                            border: "2px solid #032A51",
                        }}/>

                        <Box p={2}>
                            <Box sx={{
                                backgroundColor: 'rgba(3, 42, 81, 1)',
                                width: '350px',
                                height: '400px',
                                display: 'flex',
                                flexDirection: 'column',
                                padding: '50',
                                textAlign: 'center',
                                alignItems: 'center',
                                justifyContent: "center",
                            }}>

                                <img style={{width: '145px', marginBottom: '25px'}}
                                     src={logo} alt='Logo'/>

                                <Box sx={{
                                    backgroundColor: 'white',
                                    borderRadius: '100%',
                                    width: '230px',
                                    height: '230px',
                                    alignContent: 'center'
                                }}>
                                    <img style={{width: '140px'}}
                                         alt='User'
                                         src={user_logo}/>
                                </Box>
                            </Box>

                            <Box sx={{
                                backgroundColor: 'rgba(3, 42, 81, 1)',
                                width: '350px',
                                display: 'flex',
                                flexDirection: 'column',
                                padding: 1,
                                marginTop: 4,
                                textAlign: 'center',
                                alignItems: 'center',
                                justifyContent: "center",
                            }}>
                                <Typography fontWeight='bold' color='white'>
                                    DOMESTIC TRANSFER
                                </Typography>
                            </Box>

                            <Box p={2}>
                                <Button component={Link} to='/transfer'
                                        sx={{background: 'rgba(3, 42, 81, 1)', color: 'white', width: '100px'}}>
                                    BACK
                                </Button>
                            </Box>
                        </Box>
                    </Grid2>

                    <Grid2 size={{xs: 12, sm: 12, md:12, lg: 6, xl:6}}>
                        <Box p={2}>
                            <Typography fontWeight="bold" paragraph>
                                NATIONAL TRANSFER
                            </Typography>

                            <Box>
                                <Typography>
                                    AMOUNT
                                </Typography>
                                <TextField size="small" fullWidth/>
                            </Box>

                            <Box>
                                <Typography>
                                    CURRENCY OF ORIGIN
                                </Typography>
                                <TextField size="small" fullWidth/>
                            </Box>

                            <Box>
                                <Typography>
                                    DESTINATION CURRENCY
                                </Typography>
                                <TextField size="small" fullWidth/>
                            </Box>

                        </Box>

                        <Box p={2}>


                            <Typography fontWeight='bold' paragraph>
                                BENEFICIARY
                            </Typography>

                            <Box>
                                <Typography>
                                    FULL NAME
                                </Typography>
                                <TextField size="small" fullWidth/>
                            </Box>

                            <Box>
                                <Typography>
                                    ADDRESS
                                </Typography>
                                <TextField size="small" fullWidth/>
                            </Box>

                            <Box>
                                <Typography>
                                    IBAN
                                </Typography>
                                <TextField size="small" fullWidth/>
                            </Box>
                        </Box>

                        <Box p={2}>

                            <Typography fontWeight='bold' paragraph>
                                BENEFICIARY BANK
                            </Typography>

                            <Box>
                                <Typography>
                                    BANK
                                </Typography>
                                <TextField size="small" fullWidth/>
                            </Box>

                            <Box>
                                <Typography>
                                    BANK ADDRESS
                                </Typography>
                                <TextField size="small" fullWidth/>
                            </Box>

                            <Box>
                                <Typography>
                                    BANK CODE / SWIFT
                                </Typography>
                                <TextField size="small" fullWidth/>
                            </Box>

                            <Box>
                                <Typography>
                                    CITY
                                </Typography>
                                <TextField size="small" fullWidth/>
                            </Box>

                            <Box>
                                <Typography>
                                    STATE
                                </Typography>
                                <TextField size="small" fullWidth/>
                            </Box>

                            <Box>
                                <Typography>
                                    COUNTRY
                                </Typography>
                                <TextField size="small" fullWidth/>
                            </Box>


                            <Box p={1}>
                                <Button
                                    onClick={showTerms}
                                    sx={{ background: 'rgba(3, 42, 81, 1)', color: 'white', width: '100px'}}>
                                    TRANSFER
                                </Button>
                            </Box>
                        </Box>
                    </Grid2>
                </Grid2>
            </Box>
        </Box>
        <Footer/>
        <TransferDialog open={open} setOpen={setOpen} checked={checked} setChecked={setChecked}/>
    </div>
}

export default DomesticTransfer;