import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
//import AdbIcon from '@mui/icons-material/Adb';
import PersonIcon from '@mui/icons-material/Person'
import {useAuth} from "../hooks/useAuth";
import {useNavigate, Link} from "react-router-dom";
import pcb from '../assets/PCB.png';
import {FormControl, InputLabel, Select} from "@mui/material";
import {useTranslation} from "react-i18next";

function ResponsiveAppBar() {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const {logout} = useAuth();
    const navigate = useNavigate();
    const {i18n, t} = useTranslation();

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleLogout = () => {
        setAnchorElUser(null);
        if (window.confirm("Are you sure you want to sign out?")) {
            logout({replace: true}).catch(e => {
                console.log(e);
            });
        }
    }

    const handleMyProfile = () => {
        setAnchorElUser(null);
        navigate('/profile');
    }

    const onChange = (e) => {
        const lang_code = e.target.value;
        i18n.changeLanguage(lang_code);
    }

    return (
        <AppBar position="static" sx={{backgroundColor: "rgba(3, 42, 81, 1)"}}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />*/}
                    <Avatar sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}  src={pcb}/>
                    <Typography
                        variant="h6"
                        noWrap
                        component={Link}
                        to='/'
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        PCB
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{ display: { xs: 'block', md: 'none' } }}
                        >

                            <MenuItem  onClick={handleCloseNavMenu}
                                       component={Link} to='/fund'
                            >
                                <Typography sx={{ textAlign: 'center' }}>
                                    {t("appbar_fund")}
                                </Typography>
                            </MenuItem>
                            <MenuItem  onClick={handleCloseNavMenu}
                                       component={Link} to='/invest'
                            >
                                <Typography sx={{ textAlign: 'center' }}>
                                    {t("appbar_invest")}
                                </Typography>
                            </MenuItem>
                            <MenuItem  onClick={handleCloseNavMenu}
                                       component={Link} to='/transfer'
                            >
                                <Typography sx={{ textAlign: 'center' }}>
                                    {t("appbar_transfer")}
                                </Typography>
                            </MenuItem>

                        </Menu>
                    </Box>
                    {/*<AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />*/}
                    <Avatar sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}  src={pcb}/>
                    <Typography
                        variant="h5"
                        noWrap
                        component={Link}
                        to='/'
                        //href="#app-bar-with-responsive-menu"
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        PCB
                    </Typography>
                    <Box sx={{ flexGrow: 1, paddingRight: 2, display: { xs: 'none', md: 'flex' } }}>
                        <Button onClick={handleCloseNavMenu}
                                component={Link} to="/fund"
                                sx={{ my: 2, color: 'white', display: 'block' }}>
                            {t("appbar_fund")}
                        </Button>
                        <Button onClick={handleCloseNavMenu}
                                component={Link} to='/invest'
                                sx={{ my: 2, color: 'white', display: 'block' }}>
                            {t("appbar_invest")}
                        </Button>
                        <Button onClick={handleCloseNavMenu}
                                component={Link} to='/transfer'
                                sx={{ my: 2, color: 'white', display: 'block' }}>
                            {t("appbar_transfer")}
                        </Button>
                    </Box>
                    <Box sx={{flexGrow: 0,paddingRight: 2, display: {xs: 'none', md: 'flex'}}}>
                        <FormControl variant="standard" sx={{ minWidth: 80 }}>
                            <InputLabel id="demo-simple-select-standard-label" sx={{color: 'white'}}>
                                {t("appbar_language")}
                            </InputLabel>
                            <Select
                                variant="standard"
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={i18n.language}
                                onChange={onChange}
                                label="Language"
                                sx={{color: 'white'}}
                            >
                                <MenuItem value='es'>
                                    {t("appbar_spanish")}
                                </MenuItem>
                                <MenuItem value='en'>
                                    <em>{t("appbar_english")}</em>
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar>
                                    <PersonIcon/>
                                </Avatar>
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            <MenuItem onClick={handleMyProfile}>
                                <Typography sx={{textAlign: 'center'}}>
                                    {t('appbar_myprofile')}
                                </Typography>
                            </MenuItem>

                            <MenuItem sx={{display: {md: 'none'}}}>
                                <FormControl variant="standard" sx={{ minWidth: 80 }}>
                                    <InputLabel id="demo-simple-select-standard-label">
                                        {t("appbar_language")}
                                    </InputLabel>
                                    <Select
                                        variant="standard"
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={i18n.language}
                                        onChange={onChange}
                                        label="Language"
                                    >
                                        <MenuItem value='es'>
                                            {t("appbar_spanish")}
                                        </MenuItem>
                                        <MenuItem value='en'>
                                            <em>{t("appbar_english")}</em>
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </MenuItem>
                            <MenuItem onClick={handleLogout}>
                                <Typography x={{textAlign: 'center'}}>
                                    {t("appbar_signout")}
                                </Typography>
                            </MenuItem>

                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default ResponsiveAppBar;
