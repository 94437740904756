import React from 'react'
import logo from '../assets/2.png';
import {Box} from '@mui/material';

export default function Footer() {
    return (
        <div style={{
            backgroundColor: "rgba(3, 42, 81, 1)",
            width: "100%"
        }}>
            <Box display="flex" flexDirection="column" alignItems="center" sx={{height: '120px'}}>
                <img style={{width: '100%', maxWidth: '480px'}} src={logo} alt='Logo'/>
            </Box>
        </div>
    )
}
