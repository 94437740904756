import React from "react";
import {Box, Typography, Avatar} from "@mui/material";
import {Link} from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import WorkIcon from "@mui/icons-material/Work";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PaidIcon from "@mui/icons-material/Paid";
import {useTranslation} from "react-i18next";

const SideBar = ({section}) => {
    const {t } = useTranslation();

    return (
        <Box sx={{
            backgroundColor: 'rgba(3, 42, 81, 1)',
            padding: '20px',
            margin: '20px',
            alignContent: 'center'
        }}>
            <Link to="/profile/personal-info" style={{textDecoration: 'none'}}>
                <Box display='flex' flexDirection="row" alignItems="center">
                    <Box p={1}>
                        <Avatar>
                            <PersonIcon/>
                        </Avatar>
                    </Box>

                    <Typography variant='h5' sx={{color: 'white'}} fontWeight={section === "personal-info"?"bold": "normal"}>
                        {t("myprofile_op1")}
                    </Typography>
                </Box>
            </Link>
            <Link to="/profile/job" style={{textDecoration: 'none'}}>
                <Box display="flex" flexDirection="row" alignItems="center">
                    <Box p={1}>
                        <Avatar>
                            <WorkIcon/>
                        </Avatar>
                    </Box>

                    <Typography variant="h5" sx={{color: 'white'}} fontWeight={section === "job"?"bold": "normal"}>
                        {t("myprofile_op2")}
                    </Typography>
                </Box>
            </Link>
            <Link to="/profile/identity" style={{textDecoration: 'none'}}>
                <Box display="flex" flexDirection="row" alignItems="center">
                    <Box p={1}>
                        <Avatar>
                            <FingerprintIcon/>
                        </Avatar>
                    </Box>
                    <Typography variant="h5" sx={{color: 'white'}} fontWeight={section === "identity"?"bold": "normal"}>
                        {t("myprofile_op3")}
                    </Typography>
                </Box>
            </Link>
            <Link to="/profile/bank-information" style={{textDecoration: 'none'}}>
                <Box display="flex" flexDirection="row" alignItems="center">
                    <Box p={1}>
                        <Avatar>
                            <AccountBalanceIcon />
                        </Avatar>
                    </Box>
                    <Typography variant="h5" sx={{color: 'white'}} fontWeight={section === "bank"?"bold": "normal"}>
                        {t("myprofile_op4")}
                    </Typography>
                </Box>
            </Link>
            <Link to="/profile/investment" style={{textDecoration: 'none'}}>
                <Box display="flex" flexDirection="row" alignItems="center">
                    <Box p={1}>
                        <Avatar>
                            <PaidIcon/>
                        </Avatar>
                    </Box>
                    <Typography variant="h5" sx={{color: 'white'}} fontWeight={section === "investment"?"bold": "normal"}>
                        {t("myprofile_op5")}
                    </Typography>
                </Box>
            </Link>
        </Box>)
}

export default SideBar;