import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
//import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
//import DialogContentText from '@mui/material/DialogContentText';
//import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import {Link as RouterLink} from 'react-router-dom';
import {FormGroup, Link, Checkbox, FormControlLabel, Box} from "@mui/material";

const TransferDialog = ({open, setOpen, checked, setChecked}) =>  {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    return (
        <React.Fragment>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                hideBackdrop={true}
                PaperProps={{
                    style:{
                        backgroundColor: "rgba(3, 42, 81, 1)",
                        //boxShadow: 'none',
                    }
                }}
            >
                {/*<DialogTitle id="responsive-dialog-title">
                    {"Terms and Conditions"}
                </DialogTitle>*/}
                <DialogContent>

                    <Box display="flex" alignItems="center" flexDirection="column">
                        <Link component={RouterLink} to="/terms-conditions" target='_blank' rel="noopener noreferrer"
                             underline="always" style={{color: 'white', textDecorationColor: 'white', fontWeight: 'bold'}}
                        >
                            Terms and conditions
                        </Link>

                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox sx={{color: 'white', '&.Mui-checked': { color: 'white'} }}
                                                   checked={checked}
                                                   onChange={handleChange}
                                />}
                                label="ACCEPT" sx={{color: 'white'}}
                            />
                        </FormGroup>
                    </Box>

                    <Button onClick={handleClose}  sx={{
                        margin: 1,
                        backgroundColor: 'white', color: 'black',
                        width: 150,
                        '&:hover': {
                            backgroundColor: '#0069d9',
                            borderColor: '#0062cc',
                            boxShadow: 'none',
                        },
                    }}>
                        Cancel
                    </Button>

                    <Button variant="outlined" sx={{
                        margin: 1,
                        backgroundColor: 'white', color: 'black',
                        width: 150,
                        '&:hover': {
                            backgroundColor: '#0069d9',
                            borderColor: '#0062cc',
                            boxShadow: 'none',
                        },
                    }}>
                        Transfer
                    </Button>
                    {/*<DialogContentText>
                        Let Google help apps determine location. This means sending anonymous
                        location data to Google, even when no apps are running.
                    </DialogContentText>*/}
                </DialogContent>
                {/*<DialogActions>
                    <Button autoFocus onClick={handleClose} variant='outlined'>
                        Cancel
                    </Button>
                    <Button autoFocus variant="outlined">
                        Invest
                    </Button>
                </DialogActions>*/}
            </Dialog>
        </React.Fragment>
    );
}

export default TransferDialog;