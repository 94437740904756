import {API_URL} from "./Constants";

const invest = async (amount, type) => {
    const url = `${API_URL}/transactions/invest`;
    try {
        const rsp = await fetch(url, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                type: type,
                amount: +amount
            }),
        });

        return rsp.json();
    } catch (error) {
      console.log(error);
    }
}

export  {
    invest
}