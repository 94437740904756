import {API_URL} from "./Constants";
// TODO: implement loaders
//import {redirect} from 'react-router-dom';

const fetchData = async () => {
    const url = `${API_URL}/user/my-profile`;

    try {
        const rsp = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include'
        });

        if (rsp.status === 401){
            console.log("Session expired, redirecting...");
            window.location.reload();
        } else {
            return await rsp.json();
        }
    } catch (e) {
        console.log(e);
        return null;
    }
}

const fetchBalance = async () => {
    const url = `${API_URL}/user/balance`;

    try {
        const rsp = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include'
        });

        if (rsp.status === 401){
            console.log("Session expired, redirecting...");
            window.location.reload()
        } else {
            return await rsp.json();
        }

    } catch (e) {
        console.log(e);
        return [];
    }
}

const updateProfile = async ( user_data ) => {
    const url = `${API_URL}/user/update-profile`;
    try {
        const rsp = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify( user_data )
        });

        if (rsp.status === 401){
            console.log("Session expired, redirecting...");
            window.location.reload();
        } else {
            return await rsp.json();
        }

    } catch (e) {
        console.log(e);
        return null;
    }
}

export  {
    fetchData,
    fetchBalance,
    updateProfile
};