import { useEffect, useState } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useAuth } from '../hooks/useAuth'

export default function ProtectedRoute ({ children }) {
    const [isLoading, setIsLoading] = useState(true)
    const [isLogin, setIsLogin] = useState(false)
    const { validateSession } = useAuth()

    useEffect(() => {
        validateSession().then(isValid => {
            // if (!isValid) logout({ replace: true })
            setIsLogin(isValid);
        }).finally(() => {
            setIsLoading(false)
        })
    }, [])

    if (!isLogin && !isLoading) return <Navigate to='/auth/log-in' replace />

    return (
        <>
            {!isLoading && (children || <Outlet />)}
        </>
    )
}