import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
    //.use(i18nBackend)
    .use(initReactI18next)
    .init({
        fallbackLng: "es",
        lng: "es",//getCurrentLang(),
        interpolation: {
            escapeValue: false,
        },
        resources: {
            en: {
                translation: {
                    "appbar_language": "Language",
                    "appbar_english": "English",
                    "appbar_spanish": "Spanish",
                    "appbar_signout": "Sign Out",
                    "appbar_myprofile": "My Profile",
                    "appbar_fund": "FUND",
                    "appbar_invest": "INVEST",
                    "appbar_transfer": "TRANSFER",
                    "general_language": "language",
                    "general_english": "English",
                    "general_spanish": "Spanish",
                    //landing
                    "landing_sign_in": "SIGN IN",
                    "landing_create_account": "Create a New Account",
                    "landing_legend": "PRESTIGE CAPITAL BANK - ALL RIGHTS RESERVED",
                    //sign up
                    "sign_up_basic_info": "SOME BASIC INFORMATION ABOUT YOU",
                    "sign_up_name": "NAME",
                    "sign_up_lastname": "LAST NAME",
                    "sign_up_email": "EMAIL",
                    "sign_up_phone": "PHONE",
                    "sign_up_dob": "DOB",
                    "sign_up_password": "PASSWORD",
                    "sign_up_confirm_password": "CONFIRM PASSWORD",
                    "sign_up_create_account": "CREATE ACCOUNT",
                    //sign_in
                    "sign_in_email": "EMAIL",
                    "sign_in_password": "PASSWORD",
                    "sign_in_sign_in":"SIGN IN",
                    "sign_in_create_account":"Create a New Account",
                    //dashboard
                    "dashboard_available": "AVAILABLE",
                    "dashboard_balance": "BALANCE",
                    "dashboard_fund": "FUND",
                    "dashboard_invest": "INVEST",
                    "dashboard_transfer": "TRANSFER",
                    "dashboard_legend1": "MONTHLY DEPOSIT LIMITED TO $3000 USD",
                    "dashboard_legend2": "update the data in \"My profile\" to increase your limit",
                    "dashboard_transactions": "LAST TRANSACTIONS",
                    "dashboard_long_term": "Long Term",
                    "dashboard_cash_flow": "Cash Flow",
                    //invest
                    "invest_liquid_available": "LIQUID AVAILABLE",
                    "invest_lt_desc": "Maximize your returns with our AI-driven Long-Term Investment, targeting tech, crypto, and credit derivatives. Commit for a minimum of six months to achieve a 59% CAGR. Click for insights and terms.",
                    "invest_cf_desc":
                        "Enjoy a steady 2.9% monthly return with our Cashflow investment, a secure, bond-based option with bi-monthly payouts and immediate liquidity. Minimum investment term of 90 days. Click for more details.",
                    "invest_access": "ACCESS",
                    "invest_back": "BACK",
                    "invest_long_term": "Long Term",
                    "invest_cash_flow": "Cash Flow",
                    //funds
                    "fund_funds": "FUNDS",
                    "fund_deposit": "DEPOSIT",
                    "fund_transfer": "TRANSFER",
                    "fund_back": "BACK",
                    //fund => deposit
                    "fund_deposit_title": "DEPOSIT TO YOUR ACCOUNT AND GROW FINANCIALLY",
                    "fund_deposit_reference": "REFERENCE",
                    "fund_deposit_beneficiary": "BENEFICIARY",
                    "fund_deposit_bottom_text": "The transfer was carried out correctly and will be reflected in a maximum period of 24 hours, you will be notified by email.",
                    //my profile
                    "myprofile_title": "PERSONAL INFORMATION",
                    "myprofile_op1": "PERSONAL INFO",
                    "myprofile_op2": "JOB",
                    "myprofile_op3": "IDENTITY",
                    "myprofile_op4": "BANK INFORMATION",
                    "myprofile_op5": "INVESTMENT",
                    "myprofile_name": "NAME",
                    "myprofile_lastname": "LAST NAME",
                    "myprofile_email": "EMAIL",
                    "myprofile_phone": "PHONE",
                    "myprofile_dob": "DOB",
                    "myprofile_legend": "MONTHLY DEPOSIT LIMITED TO $3000 USD",
                    "myprofile_increase": "INCREASE YOUR LIMIT NOW",
                    //my profile => info
                    "myprofile_info_title": "WE NEED TO KNOW MORE ABOUT YOU",
                    "myprofile_info_dob": "DOB",
                    "myprofile_info_gender": "GENDER",
                    "myprofile_info_nationality": "NATIONALITY",
                    "myprofile_info_email": "EMAIL",
                    "myprofile_info_phone": "PHONE",
                    "myprofile_info_address": "ADDRESS",
                    "myprofile_info_send": "ENVIAR",
                    //my profile => job
                    "myprofile_job_title": "WE NEED TO KNOW MORE ABOUT YOU",
                    "myprofile_job_employer": "EMPLOYER",
                    "myprofile_job_job_title": "JOB TITLE",
                    "myprofile_job_yearly_income": "YEARLY INCOME",
                    "myprofile_job_work_email": "WORK EMAIL",
                    "myprofile_job_work_phone": "WORK PHONE",
                    "myprofile_job_send": "ENVIAR",
                    //my profile => identity
                    "myprofile_identity_title": "WE NEED TO KNOW MORE ABOUT YOU",
                    "myprofile_identity_id": "ID NUMBER",
                    "myprofile_identity_passport": "PASSPORT NUMBER",
                    "myprofile_identity_taxid": "TAX ID",
                    "myprofile_identity_dob": "DOB",
                    "myprofile_identity_personal_identification": "PERSONAL IDENTIFICATION",
                    "myprofile_identity_personal_identification_desc":
                        "Upload a photograph of both sides of an official identification such as a passport or ID",
                    "myprofile_identity_identity_verification": "IDENTITY VERIFICATION",
                    "myprofile_identity_identity_verification_desc":
                        "Upload a photograph where your face can be completely seen holding your official identification, the photograph must be completely clear and legible",
                    "myprofile_identity_send": "SEND",
                    //my profile => bank
                    "myprofile_bank_title": "WE NEED TO KNOW MORE ABOUT YOU",
                    "myprofile_bank_other_investment": "OTHER INVESTMENT",
                    "myprofile_bank_other_banks": "OTHER BANKS",
                    "myprofile_bank_account_number": "ACCOUNT NUMBER",
                    "myprofile_bank_account_type": "ACCOUNT TYPE",
                    "myprofile_bank_account_status": "ACCOUNT STATUS",
                    "myprofile_bank_account_creation_date": "ACCOUNT CREATION DATE",
                    "myprofile_bank_send": "SEND",
                    //my profile => investment
                    "myprofile_investment_title": "WE NEED TO KNOW MORE ABOUT YOU",
                    "myprofile_investment_source_funds": "SOURCE OF FUNDS",
                    "myprofile_investment_transaction_volume": "ESTIMATED TRANSACTION VOLUME",
                    "myprofile_investment_kyc_status": "KYC STATUS",
                    "myprofile_investment_aml_status": "AML CHECK STATUS",
                    "myprofile_investment_utility_bill": "UTILITY BILL",
                    "myprofile_investment_verification_date": "VERIFICATION DATE",
                    "myprofile_investment_terms": "TERMS AND CONDITIONS",
                    "myprofile_investment_view_terms": "View terms",
                    "myprofile_investment_accept": "ACCEPT",
                    "myprofile_investment_send": "ENVIAR",
                },
            },
            es: {
                translation: {
                    "appbar_language": "Idioma",
                    "appbar_english": "Inglés",
                    "appbar_spanish": "Español",
                    "appbar_signout": "Salir",
                    "appbar_myprofile": "Mi Perfil",
                    "appbar_fund": "FONDEAR",
                    "appbar_invest": "INVERTIR",
                    "appbar_transfer": "TRANSFERIR",
                    "general_language": "Idioma",
                    "general_english": "Inglés",
                    "general_spanish": "Español",
                    //landing
                    "landing_sign_in": "INICIAR SESIÓN",
                    "landing_create_account": "Crea una cuenta",
                    "landing_legend": "PRESTIGE CAPITAL BANK - TODOS LOS DERECHOS RESERVADOS",
                    //sing up
                    "sign_up_basic_info": "CUÉNTANOS MÁS SOBRE TI",
                    "sign_up_name": "NOMBRE(S)",
                    "sign_up_lastname": "APELLIDO",
                    "sign_up_email": "CORREO ELECTRÓNICO",
                    "sign_up_phone": "TELEFONO",
                    "sign_up_dob": "FECHA DE NACIMIENTO",
                    "sign_up_password": "CONTRASEÑA",
                    "sign_up_confirm_password": "CONFIRMA CONTRASEÑA",
                    "sign_up_create_account": "CREAR CUENTA",
                    //sign_in
                    "sign_in_email": "CORREO ELECTRÓNICO",
                    "sign_in_password": "CONTRASEÑA",
                    "sign_in_sign_in":"INICIAR SESIÓN",
                    "sign_in_create_account":"Crear cuenta",
                    //dashboard
                    "dashboard_available": "DISPONIBLE",
                    "dashboard_balance": "SALDO",
                    "dashboard_fund": "FONDEO",
                    "dashboard_invest": "INVERTIR",
                    "dashboard_transfer": "TRANSFERIR",
                    "dashboard_legend1": "DEPÓSITO MENSUAL LIMITADO A $3000 USD",
                    "dashboard_legend2": "actualiza tus datos en \"Mi Perfil\" para incrementar tu límite",
                    "dashboard_transactions": "ÚLTIMOS MOVIMIENTOS",
                    "dashboard_long_term": "RENTA FIJA",
                    "dashboard_cash_flow": "RENTA VARIABLE",
                    //invest
                    "invest_liquid_available": "DISPONIBLE",
                    "invest_lt_desc": "Maximice sus retornos con nuestra inversión a largo plazo impulsada por IA, enfocada en tecnología, criptomonedas y derivados crediticios. Comprométase por un mínimo de seis meses para lograr una CAGR del 59 %. Haga clic para obtener información y condiciones.",
                    "invest_cf_desc":
                        "Disfrute de una rentabilidad mensual constante del 2,9 % con nuestra inversión Cashflow, una opción segura basada en bonos con pagos bimensuales y liquidez inmediata. Plazo mínimo de inversión de 90 días. Haga clic para obtener más detalles.",
                    "invest_access": "ACCEDER",
                    "invest_back": "REGRESAR",
                    "invest_long_term": "RENTA FIJA",
                    "invest_cash_flow": "RENTA VARIABLE",
                    //fund
                    "fund_funds": "FONDOS",
                    "fund_deposit": "DEPOSITAR",
                    "fund_transfer": "TRANSFERIR",
                    "fund_back": "REGRESAR",
                    //fund => deposit
                    "fund_deposit_title": "DEPOSITA A TU CUENTA Y CRECE FINANCIERAMENTE",
                    "fund_deposit_reference": "REFERENCIA",
                    "fund_deposit_beneficiary": "BENEFICIARIO",
                    "fund_deposit_bottom_text": "La transferencia se realizó correctamente y se verá reflejada en un plazo máximo de 24 horas, se le notificará por correo electrónico.",
                    //my profile
                    "myprofile_title": "INFORMACIÓN PERSONAL",
                    "myprofile_op1": "INFORMACIÓN PERSONAL",
                    "myprofile_op2": "EMPLEO",
                    "myprofile_op3": "IDENTIDAD",
                    "myprofile_op4": "INFORMACIÓN BANCARIA",
                    "myprofile_op5": "INVERSIÓN",
                    "myprofile_name": "NOMBRE(S)",
                    "myprofile_lastname": "APELLIDO",
                    "myprofile_email": "CORREO ELECTRÓNICO",
                    "myprofile_phone": "TELEFONO",
                    "myprofile_dob": "FECHA DE NACIMIENTO",
                    "myprofile_legend": "DEPOSITO MENSUAL LIMITADO A $3000 USD",
                    "myprofile_increase": "INCREMENTA TU LÍMITE AHORA",
                    //my profile => info
                    "myprofile_info_title": "CUENTANOS MÁS DE TI",
                    "myprofile_info_dob": "FECHA DE NACIMIENTO",
                    "myprofile_info_gender": "SEXO",
                    "myprofile_info_nationality": "NACIONALIDAD",
                    "myprofile_info_email": "CORREO ELECTRÓNICO",
                    "myprofile_info_phone": "TELÉFONO",
                    "myprofile_info_address": "DIRECCIÓN",
                    "myprofile_info_send": "ENVIAR",
                    //my profile => job
                    "myprofile_job_title": "CUENTANOS MÁS DE TI",
                    "myprofile_job_employer": "EMPLEADOR",
                    "myprofile_job_job_title": "PUESTO/CARGO",
                    "myprofile_job_yearly_income": "INGRESO ANUAL",
                    "myprofile_job_work_email": "CORREO ELECTRÓNICO DE TRABAJO",
                    "myprofile_job_work_phone": "TELÉFONO DE TRABAJO",
                    "myprofile_job_send": "ENVIAR",
                    //my profile => identity
                    "myprofile_identity_title": "CUENTANOS MÁS DE TI",
                    "myprofile_identity_id": "IDENTIFICACIÓN",
                    "myprofile_identity_passport": "NÚMERO DE PASAPORTE",
                    "myprofile_identity_taxid": "IDENTIFICADOR DE IMPUESTOS",
                    "myprofile_identity_dob": "FECHA DE NACIMIENTO",
                    "myprofile_identity_personal_identification": "IDENTIFICACIÓN PERSONAL",
                    "myprofile_identity_personal_identification_desc":
                        "Sube una imagen de ambos lados de tu identificación oficial, puedes usar un pasaporte o documento de identidad",
                    "myprofile_identity_identity_verification": "VERIFICACIÓN DE IDENTIDAD",
                    "myprofile_identity_identity_verification_desc":
                        "Sube una fotografía sosteniendo tu identificación oficial, donde tu cara esté completamente visible. La fotografía debe ser completamente clara y legible.",
                    "myprofile_identity_send": "ENVIAR",
                    //my profile => bank
                    "myprofile_bank_title": "CUENTANOS MÁS DE TI",
                    "myprofile_bank_other_investment": "OTRAS INVERSIONES",
                    "myprofile_bank_other_banks": "OTROS BANCOS",
                    "myprofile_bank_account_number": "NÚMERO DE CUENTA",
                    "myprofile_bank_account_type": "TIPO DE CUENTA",
                    "myprofile_bank_account_status": "ESTATUS DE LA CUENTA",
                    "myprofile_bank_account_creation_date": "FECHA DE CREACIÓN DE LA CUENTA",
                    "myprofile_bank_send": "ENVIAR",
                    //my profile => investment
                    "myprofile_investment_title": "CUENTANOS MÁS DE TI",
                    "myprofile_investment_source_funds": "ORIGEN DE LOS FONDOS",
                    "myprofile_investment_transaction_volume": "VOLUMEN ESTIMADO DE TRANSACCIONES",
                    "myprofile_investment_kyc_status": "KYC STATUS",
                    "myprofile_investment_aml_status": "AML CHECK STATUS",
                    "myprofile_investment_utility_bill": "UTILITY BILL",
                    "myprofile_investment_verification_date": "FECHA DE VERIFICACIÓN",
                    "myprofile_investment_terms": "TERMINOS Y CONDICIONES",
                    "myprofile_investment_view_terms": "Ver términos",
                    "myprofile_investment_accept": "ACEPTAR",
                    "myprofile_investment_send": "ENVIAR",
                },
            }
        },
    });

export default i18n;