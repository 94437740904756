import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import logo from '../../assets/logo_sb.png';
import {Box, Button, Link, Typography} from "@mui/material";
import fondo from "../../assets/FONDO.jpg";

const InvestStatus = () =>  {
    return (
        <div className='main' style={{
            overflow: 'auto',
            backgroundImage: `url(${fondo})`,
            backgroundSize: "cover",
            backgroundRepeat: "repeat repeat",
            backgroundAttachment: "fixed",
            backgroundPosition: "center",
            height: "100vh",
            width: "100vw",
        }}>
            <Box p={2} textAlign="center">
                <img src={logo} style={{maxWidth: '400px'}} alt='Logo'/>

                <Typography variant='h5' style={{ color: 'white' }}>
                    SUCCESSFUL TRANSFER
                </Typography>
                <Typography style={{ color: 'white' }}>
                    Your money has been correctly invested, you can review it in investment within your profile.
                </Typography>
            </Box>

            <Box m={2} textAlign="center">
                <Button
                    component={RouterLink} to="/invest"
                    sx={{
                        margin: 1,
                        backgroundColor: 'white', color: 'black',
                        width: 150,
                        '&:hover': {
                            backgroundColor: '#0069d9',
                            borderColor: '#0062cc',
                            boxShadow: 'none',
                        },
                    }}>
                    BACK
                </Button>

            </Box>
        </div>
    )
}

export default InvestStatus;