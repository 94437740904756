import { createBrowserRouter } from 'react-router-dom'
import Typography from '@mui/material/Typography'

import App from '../App';
import SignIn from '../pages/SignIn'
import SignUp from '../pages/SignUp'
import ProtectedRoute from './ProtectedRoute'
import NoLoginRoute from './NoLoginRoute'

import Dashboard from '../pages/Dashboard';

import MyProfile from '../pages/MyProfile/MyProfile';
import PersonalInfo from '../pages/MyProfile/PersonalInfo';
import Job from "../pages/MyProfile/Job";
import Identity from "../pages/MyProfile/Identity";
import BankInformation from "../pages/MyProfile/BankInformation";
import Investment from "../pages/MyProfile/Investment";

import Fund from "../pages/Fund/Fund";
import Deposit from "../pages/Fund/Deposit";

import Invest from "../pages/Invest/Invest";
import InvestCashFlow from "../pages/Invest/InvestCashFlow";
import InvestLongTerm from "../pages/Invest/InvestLongTerm";
import InvestStatus from "../pages/Invest/InvestStatus";

import Transfer from "../pages/Transfer/Transfer";
import DomesticTransfer from "../pages/Transfer/DomesticTransfer";
import InternationalTransfer from "../pages/Transfer/InternationalTransfer";
import TransferStatus from "../pages/Transfer/TransferStatus";

import TerminosCondiciones from "../pages/TerminosCondiciones";
import TerminosInversion from "../pages/TerminosInversion";

import NotFound from "../pages/NotFound";
import Admin from "../components/Admin/Admin";

export const router = createBrowserRouter([
    {
        path: '/',
        element: <ProtectedRoute> <App /> </ProtectedRoute>,
        errorElement: <NotFound/>,
        children: [
            {
                path: '/',
                element: <Dashboard />
            },
            {
                path: '/terms-conditions',
                element: <TerminosCondiciones/>
            },
            {
                path: '/investment-terms',
                element: <TerminosInversion/>
            }
        ]
    },
    // admin dashboard
    {
        path: '/admin',
        element: <ProtectedRoute><Admin/></ProtectedRoute>,
        errorElement: <NotFound/>
    },

    // MY PROFILE
    {
        path: '/profile',
        element: <ProtectedRoute> <App /> </ProtectedRoute>,
        errorElement: <NotFound/>,
        children: [
            {
                path: '',
                element: <MyProfile/>
            },
            {
                path: 'personal-info',
                element: <PersonalInfo/>
            },
            {
                path: 'job',
                element: <Job />
            },
            {
                path: 'identity',
                element: <Identity />
            },
            {
                path: 'bank-information',
                element: <BankInformation />
            },
            {
                path: 'investment',
                element: <Investment />
            },
        ]
    },

    //FUND
    {
        path: '/fund',
        element: <ProtectedRoute> <App /> </ProtectedRoute>,
        errorElement: <NotFound/>,
        children: [
            {
                path: '',
                element: <Fund/>
            },
            {
                path: "deposit",
                element: <Deposit/>
            }
        ]
    },

    // INVEST
    {
        path: '/invest',
        element: <ProtectedRoute> <App /> </ProtectedRoute>,
        errorElement: <NotFound/>,
        children: [
            {
                path: '',
                element: <Invest/>
            },
            {
                path: 'cash-flow',
                element: <InvestCashFlow/>
            },
            {
                path: 'long-term',
                element: <InvestLongTerm/>
            },
            {
                path: 'status',
                element: <InvestStatus/>
            }
        ]
    },

    // TRANSFER
    {
        path: '/transfer',
        element: <ProtectedRoute> <App /> </ProtectedRoute>,
        errorElement: <NotFound/>,
        children: [
            {
                path: '',
                element: <Transfer/>
            },
            {path: "domestic", element: <DomesticTransfer/>},
            {path: "international", element: <InternationalTransfer/>},
            {
                path: 'status',
                element: <TransferStatus/>
            }
        ]
    },

    // NO LOGIN
    {
        path: '/auth',
        element: <NoLoginRoute />,
        errorElement: <Typography variant='h3'>404 Not Found</Typography>,
        children: [
            {
                path: 'log-in',
                element: <SignIn />
            },
            {
                path: 'sign-up',
                element: <SignUp />
            }
        ]
    }
]);
