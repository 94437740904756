import React from 'react';
import {Button, Box, Paper, Typography, TextField, Select, FormControl,
    FormControlLabel, MenuItem, Divider, Checkbox} from "@mui/material";
import {fetchClient} from "../../hooks/admin";
import {abono} from "../../hooks/admin";
import {USDollar} from "../../hooks/format";

const Client = ({client, agent_id}) => {
    const [optAbono, setOptAbono] = React.useState("available");
    const [amount, setAmount] = React.useState(0);
    const [depositLimit, setDepositLimit] = React.useState(false);

    const [data, setData] = React.useState(null);

    React.useEffect(() => {
        if (client) {
            fetchClient({client_id: client._id}).then((client_data) => {
                setData(client_data);
            })
        }
    }, [client]);

    const abonar = () => {
        if (amount > 0) {
            if (window.confirm(`¿Estas seguro de abonar $${amount} a ${optAbono}?`)) {
                let query = {
                    type: optAbono, amount: amount, client_id: client._id, agent_id: agent_id
                };

                abono(query).then(result => {
                    fetchClient({client_id: client._id}).then((client_data) => {
                        setData(client_data);
                    })
                    alert(JSON.stringify(result));
                });
            }
        } else {
            alert("El monto debe ser mayor a cero");
        }
    }

    return <div>
        {data?<Paper sx={{padding: 1, margin: 1}} elevation={3}>
            <Divider>
                Cliente
            </Divider>

            <Typography><b>Nombre:</b> {`${data.name} ${client.lastName}`}</Typography>
            <Typography><b>Email:</b> {data.email}</Typography>
            <Typography><b>Cuenta:</b> SHN-{data.account_number}</Typography>
            <Typography><b>Available:</b> {USDollar.format(data.available)}</Typography>
            <Typography><b>Long Term:</b> {USDollar.format(data.long_term)}</Typography>
            <Typography><b>Cash Flow:</b> {USDollar.format(data.cash_flow)}</Typography>
            <Divider>Acciones</Divider>
            <Typography fontWeight="bold">
                Abonar
            </Typography>
            <FormControl fullWidth>
                <Select value={optAbono} onChange={e => setOptAbono(e.target.value)}>
                    <MenuItem value="available"> Available</MenuItem>
                    <MenuItem value="cash_flow"> Cash Flow</MenuItem>
                    <MenuItem value="long_term"> Long Term</MenuItem>
                </Select>
            </FormControl>

            <Box sx={{paddingTop: 1, paddingBottom: 2}}>
                <TextField fullWidth type="number" value={amount} onChange={ e=> setAmount(e.target.value)}/>

                <Box p={1}>
                    <Button variant='outlined' onClick={() => abonar()}>
                        Abonar
                    </Button>
                </Box>
            </Box>

            <Divider>Actualizar limite de depósito</Divider>
            <FormControlLabel
                label="Límite de deposito"
                control={<Checkbox
                    checked={depositLimit}
                    onChange={(e) => setDepositLimit(e.target.checked)}
                    inputProps={{ 'aria-label': 'controlled' }}
                />}
            />
            <Box p={1}>
                <Button variant='outlined'>Aplicar cambio</Button>
            </Box>
            {/* Opciones Corregir */}
        </Paper>:<Typography>Cargando...</Typography>}
    </div>
}

export default Client;