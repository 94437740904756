import React from 'react';
//import Header from '../components/Header';
import Footer from '../components/Footer';
import {Typography, Box, Link} from "@mui/material";
import ResponsiveAppBar from "../components/ResponsiveAppBar";

const TerminosCondiciones = () => {
    return <div>
        <ResponsiveAppBar/>
        {/*<Header/>*/}
        <Box display="flex" justifyContent="center">
            <Box flexGrow={1} style={{maxWidth: 1000}}>
                <Box m={1} p={1}>

                    <Typography fontWeight='bold' gutterBottom>
                        Prestige Capital Bank LLC Mobile Banking and Remote Deposit Capture Services Agreement
                    </Typography>

                    <ul>
                        <li><Link href='#en'>English</Link></li>
                        <li><Link href='#es'>Spanish (Español)</Link></li>
                    </ul>

                    <Typography fontWeight='bold' id='en' gutterBottom>
                        Introduction
                    </Typography>

                    <Typography gutterBottom>
                        This Mobile Banking and Remote Deposit Capture Services Agreement ("Agreement") governs your use of the Mobile Banking and Remote Deposit Capture services ("Services") provided by Prestige Capital Bank LLC ("PCB", "we", "us", "our"), in partnership with Alquimia Pay, which facilitates local payment methods and offers an international debit card. These Services are designed to allow you to perform a variety of financial transactions through the use of a mobile device.
                    </Typography>

                    <Typography fontWeight='bold' gutterBottom>
                        Acceptance of Terms
                    </Typography>

                    <Typography gutterBottom>
                        By registering for, accessing, or using the Services, you (the "Customer") agree to be bound by the terms of this Agreement, along with any amendments; and the terms and conditions of any and all agreements applicable to your account(s) with PCB.
                    </Typography>

                    <Typography fontWeight='bold' gutterBottom>
                        Section A: Mobile Banking Services
                    </Typography>

                    <Typography fontWeight='bold' gutterBottom>
                        1. Services Description
                    </Typography>

                    <ul>
                        <li>Mobile Banking: Enables Customers to access account information, transfer funds, pay bills, and receive notifications.</li>
                        <li>Remote Deposit Capture: Allows Customers to deposit checks electronically using mobile devices capable of capturing check images.</li>
                    </ul>

                    <Typography fontWeight="bold" gutterBottom>
                        2. Eligibility and Enrollment
                    </Typography>

                    <ul>
                        <li>Must be enrolled in PCB's Online Banking.</li>
                        <li>Requires an operational mobile device with internet access.</li>
                    </ul>

                    <Typography fontWeight="bold" gutterBottom>
                        3. User Responsibilities
                    </Typography>

                    <ul>
                        <li>Comply with all applicable laws and terms.</li>
                        <li>Safeguard mobile device and banking credentials.</li>
                        <li>Immediately report any unauthorized access.</li>
                    </ul>

                    <Typography fontWeight='bold' gutterBottom>
                        4. Limitations of Use
                    </Typography>

                    <ul>
                        <li>Use services for legitimate personal or business financial transactions only.</li>
                        <li>Adhere to limitations on deposit amounts and frequency per the guidelines set by PCB.</li>
                    </ul>

                    <Typography fontWeight='bold' gutterBottom>
                        5. Fees and Charges
                    </Typography>

                    <ul>
                        <li>No additional fees for using Mobile Banking or RDC, subject to change with notice.</li>
                        <li>Standard account fees and carrier rates may apply.</li>
                    </ul>

                    <Typography fontWeight="bold" gutterBottom>
                        Section B: Terms and Conditions
                    </Typography>

                    <Typography fontWeight='bold' gutterBottom>
                        6. Modifications to the Agreement
                    </Typography>

                    <ul>
                        <li>PCB reserves the right to modify this Agreement at any time.</li>
                        <li> Continued use of the Services after changes constitutes acceptance of the new terms.</li>
                    </ul>

                    <Typography fontWeight='bold' gutterBottom>
                        7. Privacy and Confidentiality
                    </Typography>

                    <ul>
                        <li>Commitment to protect your personal and financial information as detailed in our Privacy Policy.</li>
                        <li>Use of information is strictly for providing and improving service offerings.</li>
                    </ul>

                    <Typography fontWeight='bold' gutterBottom>
                        8. Termination of Services
                    </Typography>

                    <ul>
                        <li>PCB may terminate or suspend Services to any Customer for breach of terms, security risks, or regulatory reasons.</li>
                        <li>Customers may terminate this Agreement by written notice to PCB.</li>
                    </ul>

                    <Typography fontWeight='bold' gutterBottom>
                        9. Liability and Indemnification
                    </Typography>

                    <ul>
                        <li>PCB is not liable for indirect or consequential damages arising out of the use or inability to use the Services.</li>
                        <li>Customers agree to indemnify PCB against any losses due to breach of this Agreement or unauthorized use of the Services.</li>
                    </ul>

                    <Typography fontWeight='bold' gutterBottom>
                        10. Dispute Resolution
                    </Typography>
                    <ul>
                        <li>Any disputes arising under this Agreement will be resolved in arbitration or small claims court, subject to applicable law.</li>
                        <li>PCB will cooperate with law enforcement and court orders relating to your use of the Services as required by law.</li>
                    </ul>

                    <Typography fontWeight='bold' gutterBottom>
                        11. Governing Law
                    </Typography>

                    <ul>
                        <li>
                            This Agreement is governed by the laws of Ginebra, without regard to its conflicts of law provisions.
                        </li>
                    </ul>

                    <Typography fontWeight='bold' gutterBottom>
                        12. Contact Information
                    </Typography>

                    <ul>
                        <li>
                            For questions or concerns regarding the Services, Customers may contact PCB Customer Service at [Customer Service Email] or [Customer Service Phone Number].
                        </li>
                    </ul>

                    <Typography fontWeight='bold' gutterBottom>
                        Acknowledgment and Agreement
                    </Typography>

                    <ul>
                        <li>By using the Services provided by PCB, you acknowledge that you have read, understood, and agreed to be bound by this Agreement and any subsequent modifications.</li>
                    </ul>

                    <Typography fontWeight='bold' gutterBottom>
                        End of Agreement.
                    </Typography>

                    <Typography id='es' gutterBottom>
                        en español:
                    </Typography>

                    <Typography fontWeight='bold' gutterBottom>
                        Acuerdo de Servicios de Banca Móvil y Captura de Depósitos Remotos de Prestige Capital Bank LLC
                    </Typography>

                    <Typography fontWeight='bold' gutterBottom>
                        Introducción
                    </Typography>

                    <Typography gutterBottom>
                        Este Acuerdo de Servicios de Banca Móvil y Captura de Depósitos Remotos ("Acuerdo") regula su uso de los servicios de Banca Móvil y Captura de Depósitos Remotos ("Servicios") proporcionados por Prestige Capital Bank LLC ("PCB", "nosotros"), en asociación con Alquimia Pay, que facilita métodos de pago locales y ofrece una tarjeta de débito internacional. Estos Servicios están diseñados para permitirle realizar una variedad de transacciones financieras mediante el uso de un dispositivo móvil.
                    </Typography>

                    <Typography fontWeight='bold' gutterBottom>
                        Aceptación de Términos
                    </Typography>

                    <Typography gutterBottom>
                        Al registrarse, acceder o utilizar los Servicios, usted (el "Cliente") acepta estar vinculado por los términos de este Acuerdo, junto con cualquier modificación; y los términos y condiciones de todos y cada uno de los acuerdos aplicables a sus cuentas con PCB.
                    </Typography>

                    <Typography fontWeight='bold' gutterBottom>
                        Sección A: Servicios de Banca Móvil
                    </Typography>

                    <Typography fontWeight='bold' gutterBottom>
                        1. Descripción de los Servicios
                    </Typography>

                    <Typography gutterBottom>
                        Banca Móvil: Permite a los Clientes acceder a información de cuentas, transferir fondos, pagar facturas y recibir notificaciones. Captura de Depósitos Remotos: Permite a los Clientes depositar cheques electrónicamente usando dispositivos móviles capaces de capturar imágenes de cheques.
                    </Typography>

                    <Typography fontWeight='bold' gutterBottom>
                        2. Elegibilidad e Inscripción
                    </Typography>

                    <Typography gutterBottom>
                        Debe estar inscrito en la Banca en Línea de PCB. Requiere un dispositivo móvil operativo con acceso a internet.
                    </Typography>

                    <Typography fontWeight='bold' gutterBottom>
                        3. Responsabilidades del Usuario
                    </Typography>

                    <Typography gutterBottom>
                        Cumplir con todas las leyes y términos aplicables. Proteger el dispositivo móvil y las credenciales bancarias. Informar inmediatamente cualquier acceso no autorizado.
                    </Typography>

                    <Typography fontWeight='bold' gutterBottom>
                        4. Limitaciones de Uso
                    </Typography>

                    <Typography gutterBottom>
                        Utilizar los servicios solo para transacciones financieras personales o comerciales legítimas. Adherirse a las limitaciones en montos de depósito y frecuencia según las pautas establecidas por PCB.
                    </Typography>

                    <Typography fontWeight='bold' gutterBottom>
                        5. Tarifas y Cargos
                    </Typography>

                    <Typography gutterBottom>
                        Sin tarifas adicionales por usar Banca Móvil o Captura de Depósitos Remotos, sujeto a cambios con aviso. Pueden aplicarse tarifas estándar de cuenta y tarifas de operador.
                    </Typography>


                    <Typography fontWeight='bold' gutterBottom>
                        Sección B: Términos y Condiciones
                    </Typography>

                    <Typography fontWeight='bold' gutterBottom>
                        6. Modificaciones al Acuerdo
                    </Typography>

                    <Typography gutterBottom>
                        PCB se reserva el derecho de modificar este Acuerdo en cualquier momento. El uso continuado de los Servicios después de los cambios constituye la aceptación de los nuevos términos.
                    </Typography>

                    <Typography fontWeight='bold' gutterBottom>
                        7. Privacidad y Confidencialidad
                    </Typography>

                    <Typography gutterBottom>
                        Compromiso de proteger su información personal y financiera como se detalla en nuestra Política de Privacidad. El uso de la información es estrictamente para proporcionar y mejorar las ofertas de servicio.
                    </Typography>

                    <Typography fontWeight='bold' gutterBottom>
                        8. Terminación de Servicios
                    </Typography>

                    <Typography gutterBottom>
                        PCB puede terminar o suspender los Servicios a cualquier Cliente por incumplimiento de términos, riesgos de seguridad o razones regulatorias. Los Clientes pueden terminar este Acuerdo mediante notificación escrita a PCB.
                    </Typography>

                    <Typography fontWeight='bold' gutterBottom>
                        9. Responsabilidad e Indemnización
                    </Typography>

                    <Typography gutterBottom>
                        PCB no es responsable por daños indirectos o consecuentes que surjan del uso o la incapacidad de usar los Servicios. Los Clientes acuerdan indemnizar a PCB contra cualquier pérdida debido al incumplimiento de este Acuerdo o uso no autorizado de los Servicios.
                    </Typography>

                    <Typography fontWeight='bold' gutterBottom>
                        10. Resolución de Disputas
                    </Typography>

                    <Typography gutterBottom>
                        Cualquier disputa que surja bajo este Acuerdo será resuelta en arbitraje o en un tribunal de reclamos menores, sujeto a la ley aplicable. PCB cooperará con las autoridades policiales y órdenes judiciales relacionadas con su uso de los Servicios según lo requiera la ley.
                    </Typography>

                    <Typography fontWeight='bold' gutterBottom>
                        11. Ley Aplicable
                    </Typography>

                    <Typography gutterBottom>
                        Este Acuerdo se rige por las leyes de Ginebra, sin tener en cuenta sus disposiciones sobre conflictos de leyes.
                    </Typography>

                    <Typography fontWeight='bold' gutterBottom>
                        12. Información de Contacto
                    </Typography>

                    <Typography gutterBottom>
                        Para preguntas o inquietudes respecto a los Servicios, los Clientes pueden contactar al Servicio al Cliente de PCB en [Correo Electrónico del Servicio al Cliente] o [Número de Teléfono del Servicio al Cliente].
                    </Typography>

                    <Typography fontWeight='bold' gutterBottom>
                        Reconocimiento y Acuerdo
                    </Typography>

                    <Typography gutterBottom>
                        Al usar los Servicios proporcionados por PCB, usted reconoce que ha leído, entendido y aceptado estar vinculado por este Acuerdo y cualquier modificación posterior.
                    </Typography>

                </Box>
            </Box>
        </Box>
        <Footer/>
    </div>
}

export default TerminosCondiciones;