import React from 'react';
//import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Link } from 'react-router-dom';
import Funds from '../../components/PieChart/Funds';
import {Typography, Button, Grid2, Box} from "@mui/material";
import {fetchBalance} from "../../hooks/fetchData";
import {useTranslation} from "react-i18next";
import {USDollar} from "../../hooks/format";
import ResponsiveAppBar from "../../components/ResponsiveAppBar";

export default function Fund() {
    const [assets, setAssets] = React.useState([]);
    const [name, setName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [balance, setBalance] = React.useState(0);
    const [accNumber, setAccNumber] = React.useState("")
    const {t}= useTranslation();


    React.useState( () => {
        fetchBalance().then( data => {
            setName(data.name);
            setLastName(data.lastName);
            setAssets(data.assets);
            setBalance(data.balance);
            setAccNumber(data.account_number);
        }).catch( error => {
            setAssets([]);
            console.log(error)
        });
    }, []);

    return (
        <div>
            {/*<Header />*/}
            <ResponsiveAppBar/>
            <Box display="flex" justifyContent="center">
                <Box flexGrow={1} style={{maxWidth: 1000, minHeight: 'calc(100vh - 180px)', paddingTop: 10, paddingBottom: 10}}>
                    <Grid2 container spacing={2}>
                        <Grid2 size={{xs: 12, sm:12 ,md: 12, lg:6, xl:6}}>
                            <Box p={2}>
                                <Typography variant='h3'>
                                    {`${name} ${lastName}`}
                                </Typography>

                                <hr style={{
                                    border: "2px solid #032A51",
                                }}/>

                                <Typography fontWeight="bold">
                                    {`SHN-${accNumber}`}
                                </Typography>

                            </Box>

                            <Box p={2}>
                                <Typography variant="h5">
                                    {t("fund_funds")}
                                </Typography>

                                <Typography variant="h5" fontWeight='bold'>
                                    {USDollar.format(balance)} USD
                                </Typography>
                            </Box>

                            <Box display='flex' flexDirection="row">
                                <Box p={1}>
                                    <Button component={Link} to='/fund/deposit'
                                            style={{background: 'rgba(3, 42, 81, 1)', color: 'white', minWidth: '100px'}}>
                                        {t("fund_deposit")}
                                    </Button>
                                </Box>
                                <Box p={1}>
                                    <Button component={Link} to='/transfer'
                                            style={{background: 'rgba(3, 42, 81, 1)', color: 'white', minWidth: '100px'}}>
                                        {t("fund_transfer")}
                                    </Button>
                                </Box>
                            </Box>

                            <Box p={1}>
                                <Button component={Link} to='/'
                                        style={{background: 'rgba(3, 42, 81, 1)', color: 'white', minWidth: '100px'}}>
                                    {t("fund_back")}
                                </Button>
                            </Box>

                        </Grid2>

                        <Grid2 size={{xs: 12, sm:12, md:12, lg:6, xl:6}}>
                            {assets.length > 0 &&
                                <Box display="flex" alignItems="center" justifyContent="center"  sx={{ height: '100%' }}>
                                    <div style={{height: '600px', minWidth: '320px', maxWidth: '600px'}}>
                                        <Funds data={assets}/>
                                    </div>
                                </Box>
                            }
                        </Grid2>
                    </Grid2>
                </Box>
            </Box>
            <Footer/>
        </div>
    )
}
