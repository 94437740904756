import React from 'react';
import Footer from '../../components/Footer';
//import Header from '../../components/Header';
import { Link as RouterLink } from 'react-router-dom';
import {fetchData, updateProfile} from "../../hooks/fetchData";
import {Link, Checkbox, Box, FormControlLabel, Grid2, Typography, Button, TextField} from "@mui/material";
import SideBar from "./SideBar";
import {useTranslation} from "react-i18next";
import ResponsiveAppBar from "../../components/ResponsiveAppBar";

export default function Investment() {
  const [sourceOfFunds, setSourceOfFunds] = React.useState('');
  const [estimatedTransactionsVolume, setEstimatedTransactionsVolume] = React.useState('');
  const [kycStatus, setKycStatus] = React.useState('');
  const [amlCheckStatus, setAmlCheckStatus] = React.useState('');
  const [utilityBill, setUtilityBill] = React.useState('');
  const [day, setDay] = React.useState('');
  const [month, setMonth] = React.useState('');
  const [year, setYear] = React.useState('');
  const [terms, setTerms] = React.useState(false);
  const {t} = useTranslation();

  React.useEffect(() => {
    fetchData().then(data => {
      const {investment_info} = data;
      setSourceOfFunds(investment_info.source_of_funds || '');
      setEstimatedTransactionsVolume(investment_info.estimated_transactions_volume ||'');
      setKycStatus(investment_info.kyc_status || '');
      setAmlCheckStatus(investment_info.aml_check_status || '');
      setUtilityBill(investment_info.utility_bill || '');
      setTerms(investment_info.terms_accepted || false);

      const {verification_date} = investment_info;
      if (verification_date){
        setDay(verification_date.day || '' );
        setMonth(verification_date.month || '');
        setYear(verification_date.year || '');
      } else {
        setDay('' );
        setMonth('');
        setYear( '');
      }

    }).catch(e => {
      console.log(e);
      //TODO: reload if 401
    });
  }, []);

  const handleSubmit = async () => {
    updateProfile({
      investment_info: {
        source_of_funds: sourceOfFunds,
        estimated_transactions_volume: estimatedTransactionsVolume,
        kyc_status: kycStatus,
        aml_check_status: amlCheckStatus,
        utility_bill: utilityBill,
        verification_date: {
          day: day,
          month: month,
          year: year,
        },
        terms_accepted: terms
      }
    }).then(data => {
      alert('Data updated successfully.');
      console.log(data);
    }).catch(err => {
      console.log(err);
    });
  }

  const handleChange = (e) => {
    setTerms(e.target.checked)
    console.log(terms)
  }

  return (
      <div className='main'>
        {/*<Header />*/}
        <ResponsiveAppBar/>
        <Box display="flex" justifyContent='center'>
          <Box flexGrow={1} style={{maxWidth: 1000, paddingTop: 10, paddingBottom: 10, minHeight: 'calc(100vh - 180px)' }}>
            <Grid2 container spacing={2}>
              <Grid2 size={{xs: 12, sm: 12, md: 12, lg:6, xl:6}}>
                <SideBar section="investment"/>
              </Grid2>

              <Grid2 size={{xs: 12, sm:12, md:12, lg:6, xl:6}}>
                <Box p={1}>
                  <Typography variant="h5">
                    {t("myprofile_investment_title")}
                  </Typography>
                </Box>

                <form>
                  <Box p={1}>
                    <Typography fontWeight="bold">
                      {t("myprofile_investment_source_funds")}
                    </Typography>
                    <TextField placeholder="SOURCE OF FUNDS" name="name" value={sourceOfFunds} onChange={e => {
                      setSourceOfFunds(e.target.value)
                    }} size="small" fullWidth/>
                  </Box>

                  <Box p={1}>
                    <Typography fontWeight="bold">
                      {t("myprofile_investment_transaction_volume")}
                    </Typography>
                    <TextField placeholder="ESTIMATED TRANSACTIONS VOLUME" name="estimated_transactions_value"
                               value={estimatedTransactionsVolume} onChange={e => {
                      setEstimatedTransactionsVolume(e.target.value)
                    }} size="small" fullWidth/>
                  </Box>

                  <Box p={1}>
                    <Typography fontWeight="bold">
                      {t("myprofile_investment_kyc_status")}
                    </Typography>
                    <TextField placeholder="KYC STATUS" name="kyc_status" value={kycStatus} onChange={e => {
                      setKycStatus(e.target.value)
                    }} size="small" fullWidth/>
                  </Box>

                  <Box p={1}>
                    <Typography fontWeight="bold">
                      {t("myprofile_investment_aml_status")}
                    </Typography>
                    <TextField placeholder="AML CHECK STATUS" name='aml_check_status' value={amlCheckStatus}
                               onChange={e => {
                                 setAmlCheckStatus(e.target.value)
                               }}
                               size="small" fullWidth/>
                  </Box>

                  <Box p={1}>
                    <Typography fontWeight="bold">
                      {t("myprofile_investment_utility_bill")}
                    </Typography>
                    <TextField placeholder="UTILITY BILL" name="utility_bill" value={utilityBill} onChange={e => {
                      setUtilityBill(e.target.value)
                    }} size="small" fullWidth/>
                  </Box>

                  <Box p={1}>
                    <Typography fontWeight="bold">
                      {t("myprofile_investment_verification_date")}
                    </Typography>
                  </Box>

                  <Box display='flex' flexDirection='row' flexWrap='wrap'>
                    <Box p={1}>
                      <TextField placeholder="Day" name="day" value={day} onChange={e => { setDay(e.target.value)}}
                                 style={{width: '120px'}} size="small"/>
                    </Box>
                    <Box p={1}>
                      <TextField placeholder="Month" name="month" value={month} onChange={e => setMonth(e.target.value)}
                                 style={{width: '120px'}} size="small"
                      />
                    </Box>
                    <Box p={1}>
                      <TextField placeholder="Year" name="year" value={year} onChange={e => setYear(e.target.value)}
                                 style={{width: '120px'}} size="small"
                      />
                    </Box>
                  </Box>

                  <Box p={1}>
                    <Typography fontWeight="bold">
                      {t("myprofile_investment_terms")}
                    </Typography>

                    <Link component={RouterLink} to='/terms-conditions' target="_blank">
                      {t("myprofile_investment_view_terms")}
                    </Link>
                  </Box>

                  <Box p={1}>
                    <FormControlLabel control={
                      <Checkbox
                          checked={terms}
                          onChange={handleChange}
                          inputProps={{'aria-label': 'controlled'}}
                      />} label={t("myprofile_investment_accept")}/>
                  </Box>

                  <Box p={1}>
                    <Button type='button' style={{background: 'rgba(3, 42, 81, 1)', color: 'white', width: '300px'}}
                            onClick={() => handleSubmit()}>
                      {t("myprofile_investment_send")}
                    </Button>
                  </Box>
                </form>
              </Grid2>
            </Grid2>
          </Box>
        </Box>
        <Footer/>
      </div>
  )
}
