import { useNavigate } from 'react-router-dom'
import { checkStatus, loginUser, logoutUser } from './auth'

export function useAuth () {
    const navigate = useNavigate()

    const login = async (data) =>  {
        const d = await loginUser(data);
        if (!d.isValid) throw Error(d.data);
        navigate('/');
    }

    const logout = async ({ replace = false }) => {
        const d = await logoutUser();
        if (!d.isValid) throw Error("Can't Log-out");
        navigate('/auth/log-in', { replace });
    }

    const validateSession = async () => {
        const res = await checkStatus()
        return res.isValid
    }

    return {
        login,
        logout,
        validateSession
    }
}
