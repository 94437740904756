import React from 'react';
import './Popup.css';

export default function Popup({ isOpen, message, onClose }) {
  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <button onClick={onClose} className="close-popup-button">&times;</button>
        <h2>Error</h2>
        <p>{message}</p>
      </div>
    </div>
  );
}