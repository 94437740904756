import React from 'react';
import {Typography, Box, Button} from "@mui/material";
import {Link} from "react-router-dom";
import logo from '../assets/logo_azul_sb.png';
import HomeIcon from '@mui/icons-material/Home';
import HeaderNA from "../components/HeaderNA";
import Footer from "../components/Footer";

export default function NotFound() {
    return (
        <div>
            <HeaderNA/>
            <Box p={2} m={2} textAlign="center" minHeight="calc(100vh - 230px)">

                <img src={logo} alt='Logo' style={{maxWidth:500}}/>
                <Typography variant='h2' gutterBottom>
                    404
                </Typography>
                <Typography variant="h4" gutterBottom>
                    Page Not Found
                </Typography>

                <Button component={Link} to='/' variant="contained" startIcon={<HomeIcon/>}>
                    Go Back
                </Button>

            </Box>
            <Footer/>
        </div>
    )
}
