import React from 'react';
import Footer from '../components/Footer';
import HeaderNA from '../components/HeaderNA';
import { useState } from 'react';
import Popup from '../components/Popup'  // Importa el componente Popup
import {Button, Grid2, Box, Typography, TextField} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import 'react-phone-number-input/style.css'
import PhoneInput from "react-phone-number-input";
import b from "../assets/b.png";
//import {validPhone} from "../hooks/format";

export default function SignUp() {
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [password, setPassword] = useState('');
  const [passwordV, setPasswordV] = useState('');
  const [popupMessage, setPopupMessage] = useState('');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const {t} = useTranslation();

  const navigate = useNavigate();

  async function handleSubmit(e) {
    e.preventDefault();

    if (password !== passwordV) {
      setPopupMessage('Passwords do not match');
      setIsPopupOpen(true);
      return;
    }

    try {
      const rsp = await fetch(`${process.env.REACT_APP_SERVER_URL}/auth/signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name,
          lastName,
          email,
          phone,
          day,
          month,
          year,
          password,
          passwordV,
        }),
      });

      if (rsp.status === 200) {
        // TODO: send success message
        const data = await rsp.json();
        console.log(data);
        alert('User registered successfully, redirecting...');
        //setIsPopupOpen(true);
        navigate('/auth/log-in');
      }  else {
        //TODO: Send error popup
        const data = await rsp.json();
        console.log(data);
        setPopupMessage('Error');
        setIsPopupOpen(true);
      }

    } catch (error) {
      console.log(error);
      setPopupMessage('An unexpected error occurred');
      setIsPopupOpen(true);
    }
  }

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  return (
      <div>
        <HeaderNA />

        <Box display="flex" justifyContent="center">
          <Box flexGrow={1} style={{maxWidth: 1000, paddingTop: 50, paddingBottom: 50, minHeight: 'calc(100vh - 180px)'}}>
            <Grid2 container spacing={2}>
              <Grid2 size={{xs:12, sm:12, md:12, lg:6, xl:6}}>
                <Box p={1}>
                  <Typography variant='h5'>
                    {t("sign_up_basic_info")}
                  </Typography>
                </Box>
                <form onSubmit={handleSubmit}>
                  <Box p={1}>
                    <Typography fontWeight="bold">
                      {t("sign_up_name")}
                    </Typography>
                    <TextField placeholder="Name" type='text' name="name" value={name}
                               onChange={(e) => setName(e.target.value)} required fullWidth size="small"/>
                  </Box>

                  <Box p={1}>
                    <Typography fontWeight="bold">
                      {t("sign_up_lastname")}
                    </Typography>
                    <TextField placeholder="Last name" type='text' name="lastName" value={lastName}
                               onChange={(e) => setLastName(e.target.value)} required fullWidth size="small"/>
                  </Box>

                  <Box p={1}>
                    <Typography fontWeight="bold">
                      {t("sign_up_email")}
                    </Typography>
                    <TextField placeholder="Email" type='email' name="email" value={email}
                               onChange={(e) => setEmail(e.target.value)} required fullWidth size="small"/>
                  </Box>


                  <Box p={1}>
                    <Typography fontWeight="bold">
                      {t("sign_up_phone")}
                    </Typography>
                    {/*<input placeholder="Phone" type='tel' name="phone" value={phone}
                           onChange={(e) => setPhone(e.target.value)} required/>*/}
                    <PhoneInput
                        placeholder="Phone number"
                        defaultCountry="MX"
                        value={phone}
                        onChange={setPhone}
                    />
                  </Box>

                  <Box p={1} paddingBottom={0}>
                    <Typography fontWeight="bold">
                      {t("sign_up_dob")}
                    </Typography>
                  </Box>
                  <Box display='flex' flexDirection='row' flexWrap="wrap">
                    <Box p={1}>
                      <TextField placeholder="Day" type='text' name="day" value={day}
                                 style={{width: '120px'}}
                                 onChange={(e) => setDay(e.target.value)} required size="small"/>
                    </Box>
                    <Box p={1}>
                      <TextField placeholder="Month" type='text' name="month" value={month}
                                 style={{width: '120px'}}
                                 onChange={(e) => setMonth(e.target.value)} required size="small"/>
                    </Box>
                    <Box p={1}>
                      <TextField placeholder="Year" type='text' name="year" value={year}
                                 style={{width: '120px'}}
                                 onChange={(e) => setYear(e.target.value)} required size="small"/>
                    </Box>
                  </Box>

                  <Box p={1}>
                    <Typography fontWeight="bold">
                      {t("sign_up_password")}
                    </Typography>
                    <TextField placeholder="Password" type='password' name="password" value={password}
                               onChange={(e) => setPassword(e.target.value)} required fullWidth size="small"/>
                  </Box>

                  <Box p={1}>
                    <Typography fontWeight="bold">
                      {t("sign_up_confirm_password")}
                    </Typography>
                    <TextField placeholder="Password" type='password' name="passwordV" value={passwordV}
                               onChange={(e) => setPasswordV(e.target.value)} required fullWidth size="small"/>
                  </Box>

                  <Box p={1}>
                    <Button type='submit' style={{background: 'rgba(3, 42, 81, 1)', color: 'white', width: '300px'}}>
                      {t("sign_up_create_account")}
                    </Button>
                  </Box>
                </form>
              </Grid2>

              <Grid2 size={{xs:12, sm:12, md:12, lg:6, xl:6}}>
                <Box display="flex" alignItems="center" justifyContent="center"  sx={{ height: '100%' }}>
                  <img src={b} alt='logo' style={{maxWidth: '350px'}}/>
                </Box>
              </Grid2>

            </Grid2>
          </Box>
        </Box>

        <Footer />
        <Popup isOpen={isPopupOpen} message={popupMessage} onClose={closePopup} />
      </div>
  );
}
