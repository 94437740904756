import React from 'react';
import Footer from '../../components/Footer'
//import Header from '../../components/Header'
import {fetchData, updateProfile} from '../../hooks/fetchData';
import {Box, Grid2, Typography, Button, TextField} from "@mui/material";
import SideBar from "./SideBar";
import {useTranslation} from "react-i18next";
import ResponsiveAppBar from "../../components/ResponsiveAppBar";

export default function BankInformation() {
  const [otherInvestment, setOtherInvestment] = React.useState('');
  const [otherBanks1, setOtherBanks1] = React.useState('')
  const [otherBanks2, setOtherBanks2] = React.useState('')
  const [otherBanks3, setOtherBanks3] = React.useState('')
  const [accountNumber, setAccountNumber ] = React.useState('')
  const [accountType, setAccountType] = React.useState('')
  const [accountStatus, setAccountStatus] = React.useState('')
  const {t} = useTranslation();

  //account creation
  const [day, setDay] = React.useState('')
  const [month, setMonth] = React.useState('')
  const [year, setYear] = React.useState('')

  React.useEffect( () => {
    fetchData().then(data => {
      const {bank_info} = data;

      // set initial data
      setOtherInvestment(bank_info.other_investment ||'')
      setOtherBanks1( bank_info.other_banks1 ||'')
      setOtherBanks2( bank_info.other_banks2 ||'')
      setOtherBanks3( bank_info.other_banks3 ||'')
      setAccountNumber(bank_info.account_number ||'')
      setAccountType( bank_info.account_type || '')
      setAccountStatus(bank_info.account_status ||'')

      const {account_creation} = bank_info;

      if (account_creation) {
        setDay(account_creation.day || '')
        setMonth(account_creation.month || '')
        setYear(account_creation.year || '')
      } else {
        setDay('');
        setMonth('');
        setYear('');
      }

    }).catch(e => {

      console.log(e);
      //TODO: refrescar pagina si status = 401

    })
  }, []);

  const handleSubmit = async () => {
    updateProfile({
      bank_info: {
        other_investment: otherInvestment,
        other_banks1: otherBanks1,
        other_banks2: otherBanks2,
        other_banks3: otherBanks3,
        account_number: accountNumber,
        account_type: accountType,
        account_status: accountStatus,
        account_creation: {
          day: day,
          month: month,
          year: year,
        }
      }
    }).then(data => {
      alert('Data updated successfully.');
      console.log(data);
    }).catch(err => {
      console.log(err);
    });
  }

  return (
      <div>
        {/*<Header />*/}
          <ResponsiveAppBar/>
        <Box display="flex" justifyContent='center'>
          <Box flexGrow={1} style={{maxWidth: 1000, paddingTop: 10, paddingBottom: 10, minHeight: 'calc(100vh - 180px)' }}>
            <Grid2 container spacing={2}>
              <Grid2 size={{xs:12, sm:12, md:12, lg: 6, xl:6}}>
                <SideBar section="bank" />
              </Grid2>
              <Grid2 size={{xs: 12, sm: 12,md:12, lg:6, xl:6}}>
                <Box p={1}>
                  <Typography variant='h5'>
                    {t("myprofile_bank_title")}
                  </Typography>
                </Box>
                <form>
                  <Box p={1}>
                    <Typography fontWeight="bold">
                      {t("myprofile_bank_other_investment")}
                    </Typography>
                    <TextField placeholder="OTHER INVESTMENT" name="name" value={otherInvestment}
                               onChange={e => setOtherInvestment(e.target.value)} size="small" fullWidth/>
                  </Box>

                  <Box p={1}>
                    <Typography fontWeight="bold">
                      {t("myprofile_bank_other_banks")}
                    </Typography>

                    <TextField placeholder="OTHER BANKS" name="other_banks1" value={otherBanks1}
                               onChange={e => setOtherBanks1(e.target.value)}  size="small" fullWidth/>
                  </Box>
                  <Box p={1}>
                    <TextField placeholder="OTHER BANKS" name="other_banks2" value={otherBanks2}
                               onChange={e => setOtherBanks2(e.target.value)}  size="small" fullWidth/>
                  </Box>
                  <Box p={1}>
                    <TextField placeholder="OTHER BANKS" name="other_banks3" value={otherBanks3}
                               onChange={e => setOtherBanks3(e.target.value)}  size="small" fullWidth/>
                  </Box>

                  <Box p={1}>
                    <Typography fontWeight="bold">
                      {t("myprofile_bank_account_number")}
                    </Typography>
                    <TextField placeholder="ACCOUNT NUMBER" name="account_number" value={accountNumber}
                               onChange={e => setAccountNumber(e.target.value)}  size="small" fullWidth/>
                  </Box>

                  <Box p={1}>
                    <Typography fontWeight="bold">
                      {t("myprofile_bank_account_type")}
                    </Typography>
                    <TextField placeholder="ACCOUNT TYPE" name="account_type" value={accountType}
                               onChange={e => setAccountType(e.target.value)}  size="small" fullWidth/>
                  </Box>

                  <Box p={1}>
                    <Typography fontWeight="bold">
                      {t("myprofile_bank_account_status")}
                    </Typography>
                    <TextField placeholder="ACCOUNT STATUS" name="account_status" value={accountStatus}
                               onChange={e => setAccountStatus(e.target.value)}  size="small" fullWidth/>
                  </Box>

                  <Box p={1}>
                    <Typography fontWeight="bold">
                      {t("myprofile_bank_account_creation_date")}
                    </Typography>
                  </Box>

                  <Box display='flex' flexDirection='row' flexWrap="wrap">
                    <Box p={1}>
                      <TextField placeholder="Day" name="day" value={day} onChange={e => setDay(e.target.value)}
                                 style={{width: '120px'}}  size="small"
                      />
                    </Box>
                    <Box p={1}>
                      <TextField placeholder="Month" name="month" value={month} onChange={e => setMonth(e.target.value)}
                                 style={{width: '120px'}}  size={"small"}
                      />
                    </Box>
                    <Box p={1}>
                      <TextField placeholder="Year" name="year" value={year} onChange={e => setYear(e.target.value)}
                                 style={{width: '120px'}}  size={"small"}
                      />
                    </Box>
                  </Box>

                  <Box p={1}>
                    <Button type='button' className='mt-4'
                            style={{background: 'rgba(3, 42, 81, 1)', color: 'white', width: '300px'}}
                            onClick={() => handleSubmit()}>
                      {t("myprofile_bank_send")}
                    </Button>
                  </Box>

                </form>
              </Grid2>
            </Grid2>
          </Box>
        </Box>
        <Footer />
      </div>
  )
}
