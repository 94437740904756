import React from 'react';
import Footer from '../../components/Footer';
//import Header from '../../components/Header';
import {fetchData, updateProfile} from "../../hooks/fetchData";
import {Box, Grid2, Typography, Button, FormControl, Select,  MenuItem, TextField /*FormHelperText, InputLabel*/} from '@mui/material';
import SideBar from './SideBar';
import  {useTranslation} from 'react-i18next';
import 'react-phone-number-input/style.css'
import PhoneInput from "react-phone-number-input";
import ResponsiveAppBar from "../../components/ResponsiveAppBar";

export default function PersonalInfo() {
  const [day, setDay] = React.useState('');
  const [month, setMonth] = React.useState('');
  const [year, setYear] = React.useState('');
  const [sex, setSex] = React.useState('');
  const [nationality, setNationality] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [phone, setPhone ] = React.useState('');
  const [address, setAddress] = React.useState('');
  const {t} = useTranslation();

  React.useEffect(() => {
    fetchData().then(data => {

      const {birth_date} = data;

      setDay(birth_date.day || '')
      setMonth(birth_date.month || '')
      setYear(birth_date.year || '')
      setSex(data.sex || '')
      setNationality(data.nationality || '')
      setEmail(data.email || '')
      setPhone(data.phone || '')
      setAddress(data.address || '')

    }).catch(error => {
      console.log(error)
    })
  }, [])

  const handleSubmit = async () => {
    updateProfile({
      birth_date: {
        day: day,
        month: month,
        year: year,
      },
      sex: sex,
      nationality: nationality,
      email: email,
      phone: phone,
      address: address
    }).then(data => {
      alert("Data updated successfully.");
      console.log(data);
    }).catch(err => {
      console.log(err);
    });
  }

  return (
      <div>
        {/*<Header />*/}
        <ResponsiveAppBar/>

        <Box display="flex" justifyContent="center">
          <Box flexGrow={1} style={{maxWidth: 1000, paddingTop: 10, paddingBottom: 10, minHeight: 'calc(100vh - 180px)'  }}>
            <Grid2 container spacing={2}>
              <Grid2 size={{xs: 12, sm: 12, md:12, lg: 6, xl: 6}}>
                <SideBar section="personal-info"/>
              </Grid2>

              <Grid2 size={{xs: 12, sm:12, md:12, lg:6, xl:6}}>
                <Box p={1}>
                  <Typography variant='h5'>
                    {t("myprofile_info_title")}
                  </Typography>
                </Box>

                <form>
                  <Box p={1}>
                    <Typography fontWeight="bold">
                      {t("myprofile_info_dob")}
                    </Typography>
                  </Box>

                  <Box display='flex' flexDirection="row" flexWrap="wrap">
                    <Box p={1}>
                      <TextField placeholder="Day" name="day" value={day} onChange={(e) => setDay(e.target.value)}
                             size="small"
                                 style={{width: '120px'}}
                      />
                    </Box>

                    <Box p={1}>
                      <TextField placeholder="Month" name="month" value={month} onChange={(e) => setMonth(e.target.value)}
                             style={{width: '120px'}}
                                 size="small"
                      />
                    </Box>

                    <Box p={1}>
                      <TextField placeholder="Year" name="year" value={year} onChange={(e) => setYear(e.target.value)}
                             style={{width: '120px'}}
                             size="small"
                      />
                    </Box>
                  </Box>

                  <Box p={1}>
                    <Typography fontWeight="bold">
                      {t("myprofile_info_gender")}
                    </Typography>

                    <FormControl fullWidth>
                      <Select
                          //sx={{backgroundColor: 'rgba(3, 42, 81, 0.4)'}}
                          size={'small'}
                          variant="outlined"
                          value={sex}
                          displayEmpty
                          inputProps={{ 'aria-label': 'Without label' }}
                          onChange={(e) => setSex(e.target.value)}
                      >
                        <MenuItem value="">Seleccionar</MenuItem>
                        <MenuItem value='M'>Male</MenuItem>
                        <MenuItem value='F'>Female</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>

                  <Box p={1}>
                    <Typography fontWeight="bold">
                      {t("myprofile_info_nationality")}
                    </Typography>
                    <TextField placeholder="Nationality" name="lastName" value={nationality}
                           onChange={(e) => setNationality(e.target.value)} fullWidth size="small"/>
                  </Box>

                  <Box p={1}>
                    <Typography fontWeight="bold">
                      {t("myprofile_info_email")}
                    </Typography>
                    <TextField placeholder="Email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} fullWidth size="small"/>
                  </Box>

                  <Box p={1}>
                    <Typography fontWeight="bold">
                      {t("myprofile_info_phone")}
                    </Typography>
                    {/*<input placeholder="Phone" name="phone" value={phone} onChange={(e) => setPhone(e.target.value)}/>*/}
                    <PhoneInput onChange={setPhone} value={phone}/>
                  </Box>

                  <Box p={1}>
                    <Typography fontWeight="bold">
                      {t("myprofile_info_address")}
                    </Typography>
                    <TextField placeholder="Address" name="address" value={address}
                           onChange={(e) => setAddress(e.target.value)}
                    size="small" fullWidth
                    />
                  </Box>

                  <Box p={1}>
                    <Button type='button' style={{background: 'rgba(3, 42, 81, 1)', color: 'white', width: '300px'}}
                            onClick={() => handleSubmit()}>
                      {t("myprofile_info_send")}
                    </Button>
                  </Box>
                </form>
              </Grid2>
            </Grid2>
          </Box>
        </Box>

        <Footer />
      </div>
  )
}
