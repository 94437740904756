import {API_URL} from "./Constants";

const fetchClients = async (query) => {
    const url = `${API_URL}/admin/clients`;
    const rsp = await fetch(url, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(query),
    });

    return await rsp.json();
}

const fetchClient = async (query) => {
    const url = `${API_URL}/admin/client`;
    const rsp = await fetch(url, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(query),
    });

    return await rsp.json();
}

const abono = async ({type, amount, client_id, agent_id }) => {
    const url = `${API_URL}/admin/abono`;
    const rsp = await fetch(url, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({
            type: type,
            amount: +amount,
            client_id: client_id,
            agent_id: agent_id,
        }),
    });
    return await rsp.json();
}

export {
    fetchClient,
    fetchClients,
    abono
}